/*
 * @Descripttion: 纵有千古，横有八荒，前途似海，来日方长。
 * @version: 1.0
 * @Author: 番茄
 * @Date: 2021-12-31 12:30:07
 * @LastEditors: 番茄
 * @LastEditTime: 2022-01-07 16:03:19
 */
import { createRouter, createWebHashHistory } from "vue-router";
import Login from "../views/login.vue";
import layout from '../views/layout.vue'

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { title: '登录' }
  },
  {
    path: '/layout',
    component: layout,
    redirect: '/layout/home',
    children: [
      {
        path: "home",
        component: () => import('@/views/home'),
        meta: { title: '主页' }
      },
      {
        path: "recommend",
        component: () => import('@/views/recommend'),
        meta: { title: '书城' }
      },
      // {
      //   path: "class",
      //   component: () => import('@/views/class'),
      //   meta: { title: '分类' }
      // },
      {
        path: "my",
        component: () => import('@/views/my'),
        meta: { title: '我的' }
      },
    ]
  },
  // {
  //   path: '/home',
  //   component: layout,

  // },
  {
    path: "/search",
    component: () => import('@/views/search'),
    meta: { title: '搜索' }
  },
  {
    path: "/chapter",
    name: 'chapter',
    component: () => import('@/views/chapter'),
    meta: { title: '章节' }
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
