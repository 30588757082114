/*
 * @Descripttion: 纵有千古，横有八荒，前途似海，来日方长。
 * @version: 1.0
 * @Author: 番茄
 * @Date: 2021-12-31 14:06:41
 * @LastEditors: 番茄
 * @LastEditTime: 2021-12-31 14:09:22
 */
const title = '嘟嘟阅读'

export default function getPageTitle (pageTitle) {
  if (pageTitle) {
    return `${title} - ${pageTitle}`
  } else {
    return `${title}`
  }
}