<!--
 * @Descripttion: 纵有千古，横有八荒，前途似海，来日方长。
 * @version: 1.0
 * @Author: 番茄
 * @Date: 2022-01-07 10:38:52
 * @LastEditors: 番茄
 * @LastEditTime: 2022-01-07 16:10:33
-->
<template>
  <div class='layout-num'>
    <div class="router-content">
      <router-view />
    </div>
    <nut-tabbar :visible="activeTabbar">
      <nut-tabbar-item v-for="(item,index) in tabbar"
                       :key="index"
                       :tab-title="item.title"
                       :icon="item.icon"
                       :to="item.path" />
    </nut-tabbar>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
export default {
  setup() {
    const activeTabbar = ref(0)
    const tabbar = ref([
      { icon: 'home', path: '/layout/home', title: '书架' },
      { icon: 'category', path: '/layout/recommend', title: '书城' },
      // { icon: 'find', path: '/layout', title: '分类' },
      { icon: 'my', path: '/layout/my', title: '我的' },
    ])

    const route = useRoute()
    onMounted(() => {
      activeTabbar.value = tabbar.value.findIndex((v) => v.path === route.path)
    })
    return {
      activeTabbar,
      tabbar,
    }
  },
}
</script>

<style lang='scss' scoped>
.layout-num {
  height: 100%;
  display: flex;
  flex-direction: column;

  ::v-deep .nut-tabbar {
    height: 50px;
    // position: absolute;
    // bottom: 0;
  }

  ::v-deep .nut-tabbar-item_icon-box_nav-word {
    font-size: 14px;
    margin-top: 5px;
  }
  ::v-deep .nut-tabbar-item {
    color: rgb(249, 172, 0);
  }
  .router-content {
    flex: 1;
    overflow: auto;
  }
}
</style>