/*
 * @Descripttion: 纵有千古，横有八荒，前途似海，来日方长。
 * @version: 1.0
 * @Author: 番茄
 * @Date: 2021-12-31 12:30:07
 * @LastEditors: 番茄
 * @LastEditTime: 2022-01-06 22:53:07
 */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import NutUI from "@nutui/nutui";
import "@nutui/nutui/dist/style.css";
import "@/styles/index.css";
import '@/permission.js'
import fastClick from 'fastclick'
//解决手机端300ms延迟
fastClick.attach(document.body)

createApp(App).use(router).use(NutUI).mount("#app");
