<!--
 * @Descripttion: 纵有千古，横有八荒，前途似海，来日方长。
 * @version: 1.0
 * @Author: 番茄
 * @Date: 2021-12-31 13:32:21
 * @LastEditors: 番茄
 * @LastEditTime: 2022-01-07 10:52:11
-->
<template>
  <div class="home-container">
    <p>你就像冬天的太阳</p>

    <nut-button class='btn'
                @click='toHome'
                type="primary">进入阅读<span>🍅</span></nut-button>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
export default {
  setup() {
    const router = useRouter()
    const toHome = () => {
      router.push('/layout')
    }

    return {
      toHome,
    }
  },
}
</script>

<style lang="scss" scoped>
.home-container {
  height: 100%;
  background: linear-gradient(91deg, #f1eefc, #9dc6ff 70%, #a5bcff);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 20px;
  p {
    width: 0;
    // height: 25px;
    white-space: nowrap;
    border-right: 1px solid;
    /* 不设定颜色 和字体同颜色 */
    /* background-color: pink; */
    animation: w 2s steps(8) forwards, blink 0.3s infinite;
    overflow: hidden;
    // line-height: 1;
  }

  @keyframes w {
    0% {
      width: 0;
    }
    100% {
      width: 165px;
    }
  }

  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }

  .btn {
    margin-top: 10px;
    span {
      margin-left: 3px;
    }
  }
}
</style>
