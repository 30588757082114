<!--
 * @Descripttion: 纵有千古，横有八荒，前途似海，来日方长。
 * @version: 1.0
 * @Author: 番茄
 * @Date: 2021-12-31 12:30:07
 * @LastEditors: 番茄
 * @LastEditTime: 2022-01-07 16:31:10
-->
<template>

  <router-view></router-view>

  <!-- <router-view v-slot="{ Component }">
    <transition name="fade-transform"
                mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view> -->

</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#app,
html,
body {
  width: 100%;
  height: 100%;
  // line-height: none;
  user-select: none;
}

/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
