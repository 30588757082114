/*
 * @Descripttion: 纵有千古，横有八荒，前途似海，来日方长。
 * @version: 1.0
 * @Author: 番茄
 * @Date: 2021-12-31 14:03:36
 * @LastEditors: 番茄
 * @LastEditTime: 2021-12-31 14:08:16
 */
import router from './router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import getPageTitle from '@/utils/getPageTitle'

NProgress.configure({ showSpinner: false });  // 关闭微调器

router.beforeEach(async (to, from, next) => {
  document.title = getPageTitle(to.meta.title)
  NProgress.start()
  next()
})

router.afterEach(() => {
  NProgress.done()
})